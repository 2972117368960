<template>
  <div class="college">
    <div class="container">
      <div class="create-items">
        <div class="items-tt">
          <span>您好！{{ userphone }}学员，欢迎您使用融e学平台</span>
          <p>您可以创建属于您的企业或个人的学堂</p>
          <span>您也可以申请加入学堂或联系学堂管理员加入学堂</span>
        </div>
        <div class="item-choos">
          <div class="items-CJ">
            <div class="top"></div>
            <img src="../../assets/login/icon1.png" class="images" />
            <p class="item-name">创建学堂</p>
            <p class="item-nr">您可以自行创建属于自己的学堂</p>
            <p class="item-ky">在学堂中您可以</p>
            <div class="items-ml">
              <div v-for="(item, index) in List" :key="index" class="items-fo">
                <span class="tz"></span>
                <p>{{ item.name }}</p>
              </div>
            </div>
            <el-input v-model="organizationName" placeholder="您的学堂名称" class="items-in">
            </el-input>
            <span class="bt" @click="creatCollege">创建学堂</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addorg } from '@/api/create'
import { getCookie } from '@/utils/cookies'
import to from 'await-to'
export default {
  data() {
    return {
      userphone: '',
      List: [
        { name: '职业培训' },
        { name: '学历提升' },
        { name: '问卷调查' },
        { name: '单元测试' },
        { name: '学习监察' },
        { name: '学堂管理' },
      ],
      organizationName: '',
    }
  },
  created() {
    this.userphone = getCookie('phone')
  },
  methods: {
    async creatCollege() {
      // this.$router.push({
      //   path: '/create',
      // })
      this.loading = true
      const [res, err] = await to(addorg({ organizationName: this.organizationName }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.organizationid = res.data.organizationId
      this.$router.push({
        path: '/createSuc',
        query: { name: res.data.organizationName },
      })
    },
  },
}
</script>
<style scoped>
.college {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
}
.container {
  display: flex;
  justify-content: center;
}
.create-items {
  width: 100%;
  height: 811px;
  margin-top: 55px;
}
.items-tt {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  line-height: 40px;
}
.item-choos {
  display: flex;
  margin: 55px 150px;
  justify-content: center;
}
.items-CJ {
  width: 380px;
  height: 552px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(174, 214, 255, 0.36);
  text-align: center;
}
.items-JR {
  width: 380px;
  height: 552px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(174, 214, 255, 0.36);
  text-align: center;
}
.top {
  width: 380px;
  height: 6px;
  background: #ff7b33;
}
.images {
  width: 116px;
  height: 105px;
  margin: 23px 132px 35px;
}
.item-name {
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  color: #333333;
}
.item-nr {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: #909399;
  margin-top: 17px;
}
.item-ky {
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  color: #909399;
  margin-top: 10px;
}
.items-ml {
  display: flex;
  justify-content: space-between;
  margin: 18px 80px 20px;
  box-sizing: border-box;
  flex-flow: wrap;
}
.items-in {
  width: 234px;
  height: 40px;
  margin-bottom: 20px;
}
.tz {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #409eff;
  border-radius: 50%;
  margin-top: 10px;
}
.items-fo {
  width: 80px;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.items-fo p {
  width: 64px;
  font-size: 16px;
}
.bt {
  display: inline-block;
  width: 167px;
  height: 42px;
  background: #ff7b33;
  border-radius: 21px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
}
</style>
