export default {
  // 创建学院
  addorg: '/course/supply/org/add',
  //上传图片
  uploadimage: '/third/api/huaWeiUpload/upload/image',
  //我的学院
  myorg: '/course/supply/org/myorg',
  //主体信息
  addtenant: '/course/supply/org/tenant/add',
}
